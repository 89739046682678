import { Breadcrumb } from "@/types/Breadcrumb";

export const PROGRAM_SLUG = "program";
export const PROGRAM_TITLE = "Program";
export const PROGRAM_CRUMB: Breadcrumb = {
	slug: PROGRAM_SLUG,
	title: PROGRAM_TITLE,
};

export const PROGRAM_TICKETED_SLUG = "ticketed";
export const PROGRAM_TICKETED_TITLE = "Ticketed";
export const PROGRAM_TICKETED_CRUMB: Breadcrumb = {
	slug: PROGRAM_TICKETED_SLUG,
	title: PROGRAM_TICKETED_TITLE,
};

export const PROGRAM_FREE_SLUG = "free";
export const PROGRAM_FREE_TITLE = "Free";
export const PROGRAM_FREE_CRUMB: Breadcrumb = {
	slug: PROGRAM_FREE_SLUG,
	title: PROGRAM_FREE_TITLE,
};

export const PROGRAM_RESALES_SLUG = "resales";
export const PROGRAM_RESALES_TITLE = "Resales";
export const PROGRAM_RESALES_CRUMB: Breadcrumb = {
	slug: PROGRAM_RESALES_SLUG,
	title: PROGRAM_RESALES_TITLE,
};

export const PROGRAM_HOBART_SLUG = "hobart";
export const PROGRAM_HOBART_TITLE = "nipaluna/Hobart";
export const PROGRAM_HOBART_CRUMB: Breadcrumb = {
	slug: PROGRAM_HOBART_SLUG,
	title: PROGRAM_HOBART_TITLE,
};

export const PROGRAM_LAUNCESTON_SLUG = "launceston";
export const PROGRAM_LAUNCESTON_TITLE = "Launceston";
export const PROGRAM_LAUNCESTON_CRUMB: Breadcrumb = {
	slug: PROGRAM_LAUNCESTON_SLUG,
	title: PROGRAM_LAUNCESTON_TITLE,
};

export const PROGRAM_SHEFFIELD_SLUG = "sheffield";
export const PROGRAM_SHEFFIELD_TITLE = "Sheffield";
export const PROGRAM_SHEFFIELD_CRUMB: Breadcrumb = {
	slug: PROGRAM_SHEFFIELD_SLUG,
	title: PROGRAM_SHEFFIELD_TITLE,
};

export const PROGRAM_SCHEDULE_SLUG = "schedule";
export const PROGRAM_SCHEDULE_TITLE = "Schedule";
export const PROGRAM_SCHEDULE_CRUMB: Breadcrumb = {
	slug: PROGRAM_SCHEDULE_SLUG,
	title: PROGRAM_SCHEDULE_TITLE,
};

export const PROGRAM_ARTISTS_SLUG = "artists";
export const PROGRAM_ARTISTS_TITLE = "Artists";
export const PROGRAM_ARTISTS_CRUMB: Breadcrumb = {
	slug: PROGRAM_ARTISTS_SLUG,
	title: PROGRAM_ARTISTS_TITLE,
};

export const PROGRAM_VENUES_SLUG = "venues";
export const PROGRAM_VENUES_TITLE = "Venue Map";
export const PROGRAM_VENUES_CRUMB: Breadcrumb = {
	slug: PROGRAM_VENUES_SLUG,
	title: PROGRAM_VENUES_TITLE,
};

export const PROGRAM_TODAY_SLUG = "today";
export const PROGRAM_TODAY_TITLE = "Today";
export const PROGRAM_TODAY_CRUMB: Breadcrumb = {
	slug: PROGRAM_TODAY_SLUG,
	title: PROGRAM_TODAY_TITLE,
};

export const PROGRAM_COLLECTION_SLUG = "collections";
export const PROGRAM_COLLECTION_TITLE = "Collection";
export const PROGRAM_COLLECTION_CRUMB: Breadcrumb = {
	slug: PROGRAM_COLLECTION_SLUG,
	title: PROGRAM_COLLECTION_TITLE,
	inactive: true,
};

export const TICKETS_SLUG = "tickets";
export const TICKETS_TITLE = "Tickets";
export const TICKETS_CRUMB: Breadcrumb = {
	slug: TICKETS_SLUG,
	title: TICKETS_TITLE,
};

export const TICKETS_CART_SLUG = "cart";
export const TICKETS_CART_TITLE = "Cart";
export const TICKETS_CART_CRUMB: Breadcrumb = {
	slug: TICKETS_CART_SLUG,
	title: TICKETS_CART_TITLE,
};

export const TICKETS_TRANSPORT_SLUG = "transport";
export const TICKETS_TRANSPORT_TITLE = "Transport";
export const TICKETS_TRANSPORT_CRUMB: Breadcrumb = {
	slug: TICKETS_TRANSPORT_SLUG,
	title: TICKETS_TRANSPORT_TITLE,
};

export const TICKETS_BUNDLE_SLUG = "bundles";
export const TICKETS_BUNDLE_TITLE = "Bundle";
export const TICKETS_BUNDLE_CRUMB: Breadcrumb = {
	slug: TICKETS_BUNDLE_SLUG,
	title: TICKETS_BUNDLE_TITLE,
};

export const FEAST_SLUG = "winter-feast";
export const FEAST_TITLE = "Winter Feast";
export const FEAST_CRUMB: Breadcrumb = {
	slug: FEAST_SLUG,
	title: FEAST_TITLE,
};

export const FEAST_ENTRY_SLUG = "admission";
export const FEAST_ENTRY_TITLE = "Admission";
export const FEAST_ENTRY_CRUMB: Breadcrumb = {
	slug: FEAST_ENTRY_SLUG,
	title: FEAST_ENTRY_TITLE,
};

export const FEAST_FOOD_SLUG = "food-and-drink";
export const FEAST_FOOD_TITLE = "Food + Drink";
export const FEAST_FOOD_CRUMB: Breadcrumb = {
	slug: FEAST_FOOD_SLUG,
	title: FEAST_FOOD_TITLE,
};

export const FEAST_STALLHOLDERS_SLUG = "stallholders";
export const FEAST_STALLHOLDERS_TITLE = "Stallholders";
export const FEAST_STALLHOLDERS_CRUMB: Breadcrumb = {
	slug: FEAST_STALLHOLDERS_SLUG,
	title: FEAST_STALLHOLDERS_TITLE,
};

/** Stallholders page can be linked to from a food collection containing this slug */
export const FEAST_STALLHOLDERS_FOOD_SLUG = "all";

/** Stallholder list id used for jump links */
export const FEAST_STALLHOLDERS_ID = FEAST_STALLHOLDERS_SLUG;

export const FEAST_PROGRAM_SLUG = "music";
export const FEAST_PROGRAM_TITLE = "Music";
export const FEAST_PROGRAM_CRUMB: Breadcrumb = {
	slug: FEAST_PROGRAM_SLUG,
	title: FEAST_PROGRAM_TITLE,
};

export const FEAST_FAQ_SLUG = "faq";
export const FEAST_FAQ_TITLE = "FAQ";
export const FEAST_FAQ_CRUMB: Breadcrumb = {
	slug: FEAST_FAQ_SLUG,
	title: FEAST_FAQ_TITLE,
};

export const FEAST_MUSIC_SLUG = "music";
export const FEAST_MUSIC_TITLE = "Music";
export const FEAST_MUSIC_CRUMB: Breadcrumb = {
	slug: FEAST_MUSIC_SLUG,
	title: FEAST_MUSIC_TITLE,
};

export const FEAST_ADMISSION_SLUG = "admission";
export const FEAST_ADMISSION_TITLE = "Admission";
export const FEAST_ADMISSION_CRUMB: Breadcrumb = {
	slug: FEAST_ADMISSION_SLUG,
	title: FEAST_ADMISSION_TITLE,
};

export const INFO_SLUG = "information";
export const INFO_TITLE = "Information";
export const INFO_CRUMB: Breadcrumb = {
	slug: INFO_SLUG,
	title: INFO_TITLE,
};

export const ACCESSIBILITY_SLUG = "information/accessibility";
export const ACCESSIBILITY_TITLE = "Accessibility";
export const ACCESSIBILITY_CRUMB: Breadcrumb = {
	slug: ACCESSIBILITY_SLUG,
	title: ACCESSIBILITY_TITLE,
};

export const WISHLIST_SLUG = "saved";
export const WISHLIST_TITLE = "Saved";
export const WISHLIST_CRUMB: Breadcrumb = {
	slug: WISHLIST_SLUG,
	title: WISHLIST_TITLE,
};

export const SEARCH_SLUG = "search";
export const SEARCH_TITLE = "Search";
export const SEARCH_CRUMB: Breadcrumb = {
	slug: SEARCH_SLUG,
	title: SEARCH_TITLE,
};

export const SUBSCRIBE_SLUG = "subscribe";
export const SUBSCRIBE_TITLE = "Subscribe";
export const SUBSCRIBE_CRUMB: Breadcrumb = {
	slug: SUBSCRIBE_SLUG,
	title: SUBSCRIBE_TITLE,
};

export const CART_SLUG = "tickets/cart";
export const CART_TITLE = "Cart";
export const CART_CRUMB: Breadcrumb = {
	slug: CART_SLUG,
	title: CART_TITLE,
};
