"use client";

import Error500 from "@/components/error/error500/Error500";

export default function ErrorPage() {
	return (
		<main>
			<Error500 />
		</main>
	);
}
