import { TICKETS_SLUG } from "../config/staticRoutes";

/**
 * Join slugs into an absolute path
 *
 * @param slugs
 * @return
 */
export function slugsPath(...slugs: Array<string>) {
	return `/${slugs.join("/")}`;
}

/**
 * Append slugs to an absolute base path
 *
 * @param base
 * @param slugs
 * @return
 */
export function baseSlugsPath(base: string, ...slugs: Array<string>) {
	return `${base}${slugsPath(...slugs)}`;
}

export function ticketsPath(ticketsSlug?: string) {
	if (!ticketsSlug) {
		return "";
	}

	return slugsPath(TICKETS_SLUG, ticketsSlug);
}

export function ticketsOverviewPath(slug: string) {
	return slugsPath(TICKETS_SLUG, "overview", slug);
}

export function isPathWithinSlugs(
	pathname?: string | null,
	...slugs: Array<string>
): boolean {
	if (!pathname) {
		return false;
	}

	const matcher = new RegExp(
		`^${slugsPath(...slugs).replaceAll("-", "-")}(/|$)`,
	);

	return matcher.test(pathname);
}
