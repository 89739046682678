import { PROGRAM_SLUG } from "@/lib/config/staticRoutes";
import { slugsPath } from "@/lib/helpers/paths";
import type React from "react";
import styles from "./Error500.module.scss";

export interface Error500Props {
	children?: React.ReactNode;
}

export default function Error500({
	children = "No party here",
}: Error500Props) {
	return (
		<div className={styles.container}>
			<h2 className={styles.subheading}>Bad Times</h2>
			<h1 className={styles.heading}>{children}</h1>
			<a className={styles.programLink} href={slugsPath(PROGRAM_SLUG)}>
				View Program
			</a>
		</div>
	);
}
